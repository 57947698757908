import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NewAcademicYearPage = () => (
  <Layout>
    <Seo title="New Academic Year Setup" />
    <div className="container_fixed">
      <h1>New Academic Year Setup</h1>
      <p><strong>Only Administrators can setup the new academic year</strong></p>
      <p>Please follow or print these instructions to support you setting up your new academic year classes.</p>

      <h2 className="h-small panel-warning">Please do <em>not</em> create new accounts for any existing pupils!</h2>

      <section className="mb">
        <h2 className="h3">Confirm Class Types</h2>
        <div className="grid_aside">
          <div>
            <p>Before you begin the academic year setup, please take a few moments to make sure you have correctly identified your classes in the app. You can do this from the <Link to="https://teachers.goapps.app/classes" target="_blank">classes</Link> section of the app</p>
            <h3 className="txt-large">Registration Classes</h3>
            <p>These are your standard classes and the ones that you will be setting for the new academic year. <strong>Typically a child will only be in one registration class.</strong></p>
            <h3 className="txt-large">Other Groups/Classes</h3>
            <p>These may be phonics classes/intervention groups you may have set up as additional classes for pupils.</p>
          </div>
          <div>
            <p className="panel-muted">If you have already set up your new classes- you will still need to follow the guide and confirm them for the 2022/23 academic year. This will only take a few moments. </p>
          </div>
        </div>
      </section>

      <section className="mb">
        <h2 className="h3">Ready to Start?</h2>
        <StaticImage src="../images/new-academic-year/1-get-started.png" alt="Click the banner on your dashboard" width={960} className="screenShot mb" />
        <p className="txt-large strong">Make sure you have access to your new class lists in order to complete this process.</p>
        <p>Click the banner on either your dashboard or the manage classes tab.</p>
        <p><strong>Don't see the banner?</strong><br/> You'll need administration rights for your school account. Also check the setup has not been completed by another staff member.</p>
      </section>

      <section className="mb">
        <h2 className="h3">1: Confirm Classes</h2>
        <StaticImage src="../images/new-academic-year/2-getting-ready.png" alt="Getting Started Tab" width={960} className="screenShot mb" />
        <div className="grid_aside">
          <div>
            <p>Read the information and confirm the number of current registration classes (i.e. 2021/22) is correct by typing it in the box.</p>
            <p>If it is not correct, click on the arrow to manage classes and then change the class type to registration.</p>
            <p>If you don't have any 2021/22 registration classes type '0' (Zero) into the box.</p>
          </div>
          <div>
            <StaticImage src="../images/new-academic-year/2-confirm-count.png" alt="Confirm number of registartion classes you have in BoomReader" width={530} className="screenShot mb" />
          </div>
        </div>
      </section>

      <section className="mb">
        <h2>2: Registration Classes</h2>
        <StaticImage src="../images/new-academic-year/3-list.png" alt="List of registration classes" width={960} className="screenShot mb" />
        <p className="txt-large strong">You'll see the list of your current registration classes. Click on one to begin transferring your pupils to their new (2022/23) classes.</p>
        
        <div className="panel-divider-t">
          <h3 className="txt-large">Moving Pupils</h3>
          <StaticImage src="../images/new-academic-year/3-class-move.png" alt="Move pupils" width={960} className="screenShot mb" />
          <p className="mb-1">Select all pupils, create or assign their new class and then set their target year group.</p>
          <p className="mb-1">If your classes are changing or you need to move pupils around, you can select and move single or multiple pupils as needed.</p>
          <p className="mb-1">Use the 'Year 6 leavers' or 'Leaving this school' if the pupil will no longer be at your school.</p>
          <p className="strong">You'll be able to create accounts for any pupil joining the school later in this process.</p>
          <p className="strong">Once all your students have their new classes and year groups, click the done button.</p>
        </div>

        <div className="panel-divider-t grid_two">
          <p>When all of the registration classes are complete, click done to move to the next step.</p>
          <div className="screenShot">
            <StaticImage src="../images/new-academic-year/3-done.png" alt="All registration classes done" width={530} />
          </div>
        </div>
      </section>

      <section>
        <h2>3: Other Groups/Classes</h2>
        <StaticImage src="../images/new-academic-year/4-list.png" alt="List of registration classes" width={960} className="screenShot mb" />
        <p>You can archive the entire group from here. Archiving doesn't affect any pupil data &mdash; it just closes that group.</p>
        <div className="grid_two panel-divider-t">
          <p>You can also click into a class to create the 2022/23 version in the same way you did with your registration classes.</p>
          <StaticImage src="../images/new-academic-year/4-group.png" alt="List of registration classes" width={490} className="screenShot mb" />
        </div>
      </section>

      <section>
        <h2>4: New Intake</h2>
        <p className="txt-large strong">Setup Done! You've moved all your existing pupils in your school.</p>
        <p>You can now exit this setup wizard and create any new intakes classes (e.g. nursery or reception) from the usual manage classes page.</p>
        <StaticImage src="../images/new-academic-year/4-list.png" alt="List of registration classes" width={960} className="screenShot mb" />
        <p className="strong">From here you can also create any accounts for individual pupils joining your school.</p>
      </section>
      <div className="panel-divider-t">
        <p>Please get in touch if you have any questions or need any support: <a href="mailto:support@goapps.app">support@goapps.app</a></p>
      </div>
    </div>
  </Layout>
)

export default NewAcademicYearPage